import { SIKKIM_COORDINATES, APP_HOST } from "../config/const";
import { point, polygon } from "@turf/turf";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";

const isWithinState = (latitude, longitude) => {
  const userLocation = point([longitude, latitude]);
  return booleanPointInPolygon(userLocation, sikkimCoordinates);
};

const handleRedirection = (redirectUrl = "") => {
  if (window.location.pathname.indexOf("restricted-region") < 0) {
    window.location.href = redirectUrl;
  }
};

const sikkimCoordinates = polygon([SIKKIM_COORDINATES]);

export const validateOrigin = (setErrorMessage, pageType = "website") => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const geoResult = isWithinState(latitude, longitude);

        if (!geoResult && window.location.host !== APP_HOST) {
          handleRedirection("/restricted-region");
        } else {
          if (pageType === "blocked") {
            window.location.href = "/";
          }
        }
      },
      (error) => {
        setErrorMessage("Unable to retrieve location");
        switch (error.code) {
          case error.PERMISSION_DENIED:
            setErrorMessage(
              "Permission denied. Please enable location services."
            );
            handleRedirection("/restricted-region");
            break;

          case error.POSITION_UNAVAILABLE:
            setErrorMessage("Position unavailable.");
            handleRedirection("/restricted-region");
            break;
          case error.TIMEOUT:
            setErrorMessage("Request timed out. Please try again.");
            handleRedirection("/restricted-region");
            break;
          default:
            setErrorMessage("An unknown error occurred.");
            handleRedirection("/restricted-region");
            break;
        }
      }
    );
  } else {
    setErrorMessage("Geolocation is not supported by this browser.");
  }
};

export const checkForLocationPermissions = async (setErrorMessage) => {
  const permission = await navigator.permissions.query({
    name: "geolocation",
  });
  if (permission.state === "prompt") {
    setErrorMessage("Please allow location to use website");
    handleRedirection("/restricted-region");
  }
  if (permission.state === "denied") {
    setErrorMessage("Permission denied. Please enable location services.");
    handleRedirection("/restricted-region");
  }
};
